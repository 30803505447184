body {
  padding: 0 !important;
  /* https://github.com/mui-org/material-ui/issues/17353 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

pre code {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
}

/* Query editor - highlight editable lines */
.cdr.editableArea--multi-line {
  background-color: #00cf0050;
  width: 100% !important;
}
